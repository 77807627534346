<template>
  <router-view />
</template>

<style lang="scss">
@import '../public/css/style.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 grey; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #33a1f1; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background:  blue; 
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
