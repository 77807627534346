<template>
    <div>
        <HeaderComponent />
        <br>
        <h2>!أهلا بيك</h2>
        <p><strong>أنا مساعدك الشخصي</strong></p>
        <p>يرجى إدخال البيانات التالية بدقة لمعرفة الكليات التي تناسب مجموعك</p>
        <br>
        <div class="form-group">
            <label for="percentage">ادخل مجموعك بالنسبة المئوية (بعد المعادلة)</label>
            <input type="number" id="percentage" v-model="percentage">
        </div>

        <div class="form-group">
            <label for="certificateType">اختر نوع شهادتك</label>
            <select id="certificateType" v-model="selectedCertificate">
                <option value="secondary_general">الثانوية العامة المصرية</option>
                <option value="secondary_general">ستم والنيل</option>
                <option value="azhar_secondary">الثانوية الأزهرية</option>
                <option value="arabic_foreign">شهادة عربية أو أجنبية</option>
            </select>
        </div>

        <div class="form-group">
            <label for="year">اختر سنة الحصول</label>
            <select id="year" v-model="selectedYear">
                <option value="2023">2023</option>
                <option value="2024">2024</option>
            </select>
        </div>

        <div>
            <button @click="search">بحث</button>
        </div>

        <div v-if="result" class="result">
            <p>{{ result }}</p>
            <div v-if="resultDetails" class="result-details" v-html="resultDetails"></div>
            <button v-if="result === 'الطب البشري'" @click="toggleDetails">تفاصيل</button>
        </div>

        <div v-if="showDetails" class="details">
            <p>هنا تفاصيل عن الطب البشري...</p>
        </div>
        <br>
        <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '../../../public/global/headerComponent.vue';
import FooterComponent from '../../../public/global/footerComponent.vue'; 

export default {
    name: 'MainComponent',
    components: {
        HeaderComponent,
        FooterComponent
    },
    data() {
        return {
            percentage: null,
            selectedCertificate: '',
            selectedYear: '',
            result: '',           // Holds the result message
            resultDetails: '',    // Holds additional result details
            showDetails: false    // Controls the visibility of details
        };
    },
    methods: {
        search() {
            const scores = {
                'arabic_foreign': {
                    '2024': {
                        'طب بشري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 85,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 83,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 81
                        },
                        'طب الاسنان': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 82,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 77,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 75
                        },
                        'العلاج الطبيعي': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 78,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 73,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 71
                        },
                        'الصيدلة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 73,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 68,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 66
                        },
                        'الطب البيطري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 56,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 56
                        },
                        'الهندسة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 70,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 63
                        },
                        'علوم الحاسب': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 65,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 60,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 60
                        },
            'التمريض': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'تكنولوجيا العلوم الصحية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الفنون': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الإعلام': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الآثار': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الحقوق': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'السياحة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الاقتصاد والإدارة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم السينمائية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم الأساسية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'التكنولوجيا الحيوية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'التربية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'اللغات والترجمة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم الاجتماعية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            }
                    },
                    '2023': {
                        'طب بشري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 90,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 85,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 83
                        },
                        'طب الاسنان': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 85,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 80,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 78
                        },
                        'العلاج الطبيعي': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 80,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 75,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 73
                        },
                        'الطب البيطري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 58,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 58
                        },
                        'الصيدلة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 75,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 70,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 68
                        },
                        'الهندسة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 70,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 63
                        },
                        'علوم الحاسب': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 65,
                            'جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 60,
                            'جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية': 60
                        },
            'التمريض': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'تكنولوجيا العلوم الصحية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الفنون': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الإعلام': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الآثار': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الحقوق': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'السياحة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'الاقتصاد والإدارة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم السينمائية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم الأساسية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'التكنولوجيا الحيوية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'التربية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'اللغات والترجمة': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            'العلوم الاجتماعية': {
                'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية': 58
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            }
                    }
                },
              'secondary_general': {
                    '2024': {
                        'طب بشري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 79,
                            'جامعة الجلالة,جامعة العلمين الدولية': 76,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 74
                         },
                        'طب الاسنان': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 77,
                            'جامعة الجلالة,جامعة العلمين الدولية': 75,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 73
                           },
                        'العلاج الطبيعي': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 75,
                            'جامعة الجلالة,جامعة العلمين الدولية': 74,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 72
                          },
                        'الصيدلة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 71,
                            'جامعة الجلالة,جامعة العلمين الدولية': 70,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 68
                           },
                        'الطب البيطري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 67,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65
                           },
                        'الهندسة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 68,
                            'جامعة الجلالة,جامعة العلمين الدولية': 66,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65
                         },
                        'علوم الحاسب': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 60,
                            'جامعة الجلالة,جامعة العلمين الدولية': 60,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 60
                          },
                    "التمريض": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "تكنولوجيا العلوم الصحية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الفنون": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الإعلام": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الآثار": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الحقوق": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "السياحة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الاقتصاد والإدارة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم السينمائية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الأساسية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التكنولوجيا الحيوية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التربية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "اللغات والترجمة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الاجتماعية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 53,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 53
            }
        },
                     '2023': {
                        'طب بشري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 80,
                            'جامعة الجلالة,جامعة العلمين الدولية': 77,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 75
                         },
                        'طب الاسنان': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 78,
                            'جامعة الجلالة,جامعة العلمين الدولية': 76,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 74
                           },
                        'العلاج الطبيعي': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 76,
                            'جامعة الجلالة,جامعة العلمين الدولية': 74,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 73
                          },
                        'الصيدلة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 72,
                            'جامعة الجلالة,جامعة العلمين الدولية': 71,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 70
                           },
                        'الطب البيطري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 68,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 66
                           },
                        'الهندسة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 68,
                            'جامعة الجلالة,جامعة العلمين الدولية': 66,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65
                         },
                        'علوم الحاسب': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 60,
                            'جامعة الجلالة,جامعة العلمين الدولية': 60,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 60
                          },
                    "التمريض": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "تكنولوجيا العلوم الصحية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الفنون": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الإعلام": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الآثار": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الحقوق": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "السياحة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الاقتصاد والإدارة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم السينمائية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الأساسية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التكنولوجيا الحيوية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التربية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "اللغات والترجمة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الاجتماعية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 53,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 53
            }
                    }
                },
    "azhar_secondary": {
         '2024': {
                        'طب بشري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 79,
                            'جامعة الجلالة,جامعة العلمين الدولية': 76,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 74
                         },
                        'طب الاسنان': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 77,
                            'جامعة الجلالة,جامعة العلمين الدولية': 75,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 73
                           },
                        'العلاج الطبيعي': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 75,
                            'جامعة الجلالة,جامعة العلمين الدولية': 74,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 72
                          },
                        'الصيدلة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 71,
                            'جامعة الجلالة,جامعة العلمين الدولية': 70,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 68
                           },
                        'الطب البيطري': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 67,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65
                           },
                        'الهندسة': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 68,
                            'جامعة الجلالة,جامعة العلمين الدولية': 66,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 65
                         },
                        'علوم الحاسب': {
                            'الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)': 60,
                            'جامعة الجلالة,جامعة العلمين الدولية': 60,
                            'جامعة سيناء,جامعة الملك سلمان الدولية,جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية': 60
                          },
            "التمريض": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "تكنولوجيا العلوم الصحية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الفنون": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الإعلام": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الآثار": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الحقوق": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "السياحة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "الاقتصاد والإدارة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم السينمائية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الأساسية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التكنولوجيا الحيوية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "التربية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "اللغات والترجمة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "العلوم الاجتماعية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 55,
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية عدا (جامعة 6 اكتوبر, جامعة مصر للعلوم والتكنولوجيا, جامعة أكتوبر للعلوم الحديثة والاداب - مصر الدولية)": 53
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 53,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 50,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 53
            }
        },
        "2023": {
            "طب بشري": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 90,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 85,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 83
            },
            "طب الاسنان": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 85,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 80,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 78
            },
            "العلاج الطبيعي": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 80,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 75,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 73
            },
            "الطب البيطري": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 58,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 58
            },
            "الصيدلة": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 75,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 70,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 68
            },
            "الهندسة": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 70,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 65,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 63
            },
            "علوم الحاسب": {
                "الجامعات الخاصة عدا (سيناء) والجامعات الاهلية عدا (الجلالة,العلمين الدولية, الملك سلمان الدولية,شرق بورسعيد الاهلية,الاسماعلية الجديدة الاهلية)": 65,
                "جامعة سيناء (فرع القنطرة),جامعة الجلالة,جامعة العلمين الدولية, جامعة شرق بورسعيد الاهلية,جامعة الاسماعلية الجديدة الاهلية": 60,
                "جامعة سيناء (فرع العريش),جامعة الملك سلمان الدولية": 60
            },
            "التمريض": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "تكنولوجيا العلوم الصحية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "الفنون": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "الإعلام": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "الآثار": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "الحقوق": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "السياحة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "الاقتصاد والإدارة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "العلوم السينمائية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "العلوم الأساسية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "التكنولوجيا الحيوية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "التربية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "اللغات والترجمة": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "العلوم الاجتماعية": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            },
            "النقل الدولي": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "النقل البحري والتكنولوجيا": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "تكنولوجيا المصايد": {
                "في الاكادمية العربية للعلوم والتكنولوجيا والنقل البحري": 58,
 },
            "الكليات البولي تيكنيك": {
                "في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية": 58
            }
        }
    }
}


            if (this.percentage === null || this.percentage === '') {
                this.result = 'يرجى إدخال مجموعك';
                return;
            }
            if (this.selectedCertificate === '' || this.selectedYear === '') {
                this.result = 'يرجى اختيار نوع الشهادة وسنة الحصول';
                return;
            }

            const yearData = scores[this.selectedCertificate][this.selectedYear];
            if (!yearData) {
                this.result = 'بيانات غير متاحة للسنة المحددة';
                return;
            }

            const faculties = Object.keys(yearData);
            let results = [];

            faculties.forEach(faculty => {
                const criteria = yearData[faculty];
                const matchedPlaces = [];
                for (const [key, minScore] of Object.entries(criteria)) {
                    if (this.percentage >= minScore) {
                        if (!matchedPlaces.includes(key)) {
                            matchedPlaces.push(key);
                        }
                    }
                }
                if (matchedPlaces.length > 0) {
                    results.push({
                        name: faculty,
                        link: this.getFacultyLink(faculty),
                        places: matchedPlaces.length === Object.keys(criteria).length 
                            ? 'في كل الجامعات الخاصة والاهلية وذات الطابع الخاص وافرع الجامعات الاجنبية' 
                            : matchedPlaces.join(', ')
                    });
                }
            });

            if (results.length === 0) {
                this.result = 'لا توجد كليات  يحقق مجموعك الحد الادني بها';
                this.resultDetails = '';
            } else {
                this.result = ': الكليات التي يحقق مجموعك الحد الادني بها هي';
                this.resultDetails = results.map(result => `
                    <div class="result-item">
                        <a href="${result.link}" target="_blank">${result.name}</a>
                        <div class="result-places">${result.places}</div>
                    </div>
                `).join('<br>');
            }
        },
        toggleDetails() {
            this.showDetails = !this.showDetails;
        },
        getFacultyLink(faculty) {
            const links = {
    'طب بشري': '/SmartAssistant/Faculties/MED',
    'طب الاسنان': '/SmartAssistant/Faculties/DENT',
    'العلاج الطبيعي': '/SmartAssistant/Faculties/PT',
    'الصيدلة': '/SmartAssistant/Faculties/PHARM',
    'الطب البيطري': '/SmartAssistant/Faculties/VET',
    'الهندسة': '/SmartAssistant/Faculties/ENG',
    'علوم الحاسب': '/SmartAssistant/Faculties/CS',
    'البولي تيكنيك': '/SmartAssistant/Faculties/POLY',
    'التمريض': '/SmartAssistant/Faculties/NURS',
    'تكنولوجيا العلوم الصحية': '/SmartAssistant/Faculties/HS',
    'الفنون': '/SmartAssistant/Faculties/ART',
    'الإعلام': '/SmartAssistant/Faculties/MEDIA',
    'الآثار': '/SmartAssistant/Faculties/ARCH',
    'الحقوق': '/SmartAssistant/Faculties/LAW',
    'السياحة': '/SmartAssistant/Faculties/TOUR',
    'الاقتصاد والإدارة': '/SmartAssistant/Faculties/ECON',
    'النقل الدولي': '/SmartAssistant/Faculties/ECON',
    'تكنولوجيا المصايد': '/SmartAssistant/Faculties/ECON',
    'النقل البحري والتكنولوجيا': '/SmartAssistant/Faculties/ECON',
    'العلوم السينمائية': '/SmartAssistant/Faculties/CINE',
    'العلوم الأساسية': '/SmartAssistant/Faculties/SCI',
    'التكنولوجيا الحيوية': '/SmartAssistant/Faculties/BIOTECH',
    'التربية': '/SmartAssistant/Faculties/EDU',
    'اللغات والترجمة': '/SmartAssistant/Faculties/LANG',
    'العلوم الاجتماعية': '/SmartAssistant/Faculties/SOCSCI'
};
            return links[faculty] || '#';
        }
    }
};
</script>


<style scoped>
.result {
    margin-top: 20px;
}

.result-details {
    font-size: 16px;
}

.result-item {
    margin-bottom: 10px;
}

.result-item a {
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
}

.result-item a:hover {
    text-decoration: underline;
}

.result-places {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
}

.details {
    margin-top: 20px;
}

/* Form Group Styles */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Button Styles */
button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-right: 10px; /* Add space between buttons */
}

button:hover {
    background-color: #0056b3;
}

/* Result Styles */
.result {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
}

/* Details Styles */
.details {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f0f0f0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .carousel-item img {
        height: 40vh; /* Set height as a percentage of the viewport height */
        object-fit: cover; /* Ensure image covers the area without distortion */
    }
}
</style>
